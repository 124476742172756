var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-card",
        { staticClass: "my-4 rounded-lg border", attrs: { elevation: "0" } },
        [
          _c(
            "v-card-title",
            { staticClass: "d-flex justify-space-between align-center" },
            [
              _c("div", [
                _c(
                  "span",
                  {
                    staticClass:
                      "blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12",
                  },
                  [_vm._v(_vm._s(_vm.$t("Question")))]
                ),
              ]),
              _c("div", [
                _c("span", { staticClass: "f13 font-weight-light" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("moment")(_vm.question.createDate, "from", "now")
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]
          ),
          _c("v-card-text", [
            _c("p", {
              staticClass: "f14 text-justify",
              domProps: { innerHTML: _vm._s(_vm.question.question) },
            }),
          ]),
        ],
        1
      ),
      _vm._l(_vm.question.answers, function (answer, index) {
        return _c(
          "v-card",
          {
            key: index,
            staticClass: "my-4 mx-4 rounded-lg border",
            attrs: { elevation: "0" },
          },
          [
            _c(
              "v-card-title",
              {
                staticClass: "d-flex justify-space-between align-center",
                attrs: { elevation: "0" },
              },
              [
                _c("div", [
                  _c(
                    "span",
                    {
                      staticClass:
                        "green lighten-5 green--text text--darken-1 p-2 rounded-lg f12",
                    },
                    [_vm._v(_vm._s(_vm.$t("Answer")) + " " + _vm._s(index + 1))]
                  ),
                ]),
                _c("div", [
                  _c("span", { staticClass: "f13 font-weight-light" }, [
                    _vm._v(
                      _vm._s(_vm._f("moment")(answer.createDate, "from", "now"))
                    ),
                  ]),
                ]),
              ]
            ),
            _c("v-card-text", [
              _c("p", {
                staticClass: "f14",
                domProps: { innerHTML: _vm._s(answer.answer) },
              }),
            ]),
            _c("v-divider", { staticClass: "m-0", attrs: { dark: "" } }),
            _c(
              "v-card-actions",
              {
                staticClass: "d-flex justify-space-between align-items-center",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "pointer text-decoration-none",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.openDrBottomSheet(answer.doctorId)
                      },
                    },
                  },
                  [
                    _c(
                      "v-avatar",
                      { attrs: { size: "36" } },
                      [
                        _c("v-img", {
                          attrs: {
                            "lazy-src": require("@/assets/images/profile-circle.png"),
                            src: answer.doctorProfilePic,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "ms-2 font-weight-bold f13" }, [
                      _vm._v(_vm._s(answer.doctorFullName)),
                    ]),
                  ],
                  1
                ),
                answer.liked
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "h-auto py-1",
                        attrs: {
                          flat: "",
                          depressed: "",
                          small: "",
                          color: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setUnlike(answer, index)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex d-inline-flex align-items-center",
                          },
                          [
                            _c("iconly", {
                              staticClass: "ms-1 me-0 wh-20",
                              attrs: { type: "bold", name: "heart" },
                            }),
                            answer.userLikeAnswers &&
                            answer.userLikeAnswers.length
                              ? _c(
                                  "span",
                                  { staticClass: "me-1 text-white f12" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(answer.userLikeAnswers.length) +
                                        " "
                                    ),
                                  ]
                                )
                              : _c(
                                  "span",
                                  { staticClass: "me-1 text-white f12" },
                                  [_vm._v("0")]
                                ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _c(
                      "v-btn",
                      {
                        staticClass: "h-auto py-1 border border-primary",
                        attrs: { flat: "", outlined: "", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.setLike(answer, index)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex d-inline-flex align-items-center primary--text",
                          },
                          [
                            _c("iconly", {
                              staticClass: "ms-1 me-0 wh-20",
                              attrs: { type: "bold", name: "heart" },
                            }),
                            answer.userLikeAnswers &&
                            answer.userLikeAnswers.length
                              ? _c("span", { staticClass: "me-1 f12" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(answer.userLikeAnswers.length) +
                                      " "
                                  ),
                                ])
                              : _c("span", { staticClass: "me-1 f12" }, [
                                  _vm._v("0"),
                                ]),
                          ],
                          1
                        ),
                      ]
                    ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "v-footer",
        {
          staticClass: "mb-2",
          attrs: { app: "", fixed: "", elevation: "0", color: "transparent" },
        },
        [
          _c(
            "v-container",
            {
              staticClass: "d-flex flex-column align-items-end",
              attrs: { fluid: "" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-3",
                  attrs: {
                    color: "primary",
                    rounded: "",
                    to: "/c/questions/add",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Add question")) + " + ")]
              ),
              _c(
                "v-card",
                {
                  staticClass:
                    "w-100 d-flex flew-row justify-space-between align-items-center px-2 py-1 border",
                  attrs: { rounded: "lg", elevation: "1" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "light-blue lighten-5 blue--text f14 my-1 rounded px-4 py-1",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.question.answers.length) +
                          " " +
                          _vm._s(_vm.$t("Answer")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "mx-1 px-3 py-1 d-flex align-items-center border",
                          attrs: { flat: "" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "me-2 ms-0 f14 grey--text text--darken-2",
                            },
                            [_vm._v(_vm._s(_vm.question.userViews.length))]
                          ),
                          _c("iconly", {
                            attrs: {
                              type: "light",
                              name: "show",
                              color: "#20B1EA",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.question.liked
                        ? _c(
                            "v-card",
                            {
                              staticClass:
                                "mx-1 px-3 py-1 d-flex align-items-center border",
                              attrs: { color: "primary", flat: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.setUnlikeQuestion(_vm.question)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "me-2 ms-0 f14 white--text" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.question.userLikeQuestions.length
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c("iconly", {
                                attrs: {
                                  type: "bold",
                                  name: "heart",
                                  color: "#FFFFFF",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "v-card",
                            {
                              staticClass:
                                "mx-1 px-3 py-1 d-flex align-items-center border",
                              attrs: { flat: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.setLikeQuestion(_vm.question)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "me-2 ms-0 f14 grey--text text--darken-2",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.question.userLikeQuestions.length
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c("iconly", {
                                attrs: {
                                  type: "light",
                                  name: "heart",
                                  color: "#20B1EA",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("BshDoctor", { ref: "drBottomSheet" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }